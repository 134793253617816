import {
	json,
	type LoaderFunctionArgs,
	type HeadersFunction,
} from '@remix-run/node';
import { Outlet, type ShouldRevalidateFunction } from '@remix-run/react';
import { SocketProvider } from '~/components/socket/socket-provider.tsx';
import { combineHeaders, useRouteLoaderDataTyped } from '~/utils/misc.ts';
import { makeTimings, time } from '~/utils/timing.server.ts';

export async function loader({ request, context }: LoaderFunctionArgs) {
	const timings = makeTimings('app loader');

	const [{ user, ...session }, { toast, headers: toastHeaders }] =
		await Promise.all([
			time(() => context.authSession.requireSession(), {
				type: 'load session',
				timings,
			}),
			time(() => context.toast.getToast(request), {
				type: 'load toast',
				timings,
			}),
		]);

	return json(
		{
			session,
			user,
			toast,
		},
		{
			headers: combineHeaders(toastHeaders, {
				'Server-Timing': timings.toString(),
			}),
		},
	);
}

export const shouldRevalidate: ShouldRevalidateFunction = ({
	defaultShouldRevalidate,
	currentUrl,
	nextUrl,
	formMethod,
}) => {
	if (
		(!formMethod || formMethod === 'GET') &&
		currentUrl.pathname === nextUrl.pathname
	) {
		return false;
	}
	return defaultShouldRevalidate;
};

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	return loaderHeaders;
};

export function useAppLoaderData() {
	return useRouteLoaderDataTyped<typeof loader>('routes/app+/_layout');
}

export default function AppLayout() {
	return (
		<SocketProvider>
			<Outlet />
		</SocketProvider>
	);
}
